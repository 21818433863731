import React from 'react'
import { Link, graphql } from 'gatsby'

import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

// import Img from 'gatsby-image'

// import pic01 from '../assets/images/landry-gapangwa-737553-unsplash.jpg'
// import pic02 from '../assets/images/owen-cl-148926-unsplash.jpg'
// import pic03 from '../assets/images/brandon-nelson-667698-unsplash.jpg'
// import pic04 from '../assets/images/jeremiah-higgins-208802-unsplash.jpg'
// import pic05 from '../assets/images/joshua-sortino-1281200-unsplash.jpg'
// import pic06 from '../assets/images/ronan-680076-unsplash.jpg'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="HOVR">
          <title>HOVR - Hovercraft for San Francisco</title>
          <meta name="description" content="Landing Page" />
          <html lang="en" amp />
        </Helmet>

        <Banner
          bannerImage={this.props.data.bannerImage}
          bannerSubtitle="Access areas previously unreachable by ferries"
          bannerTitle="Hovercraft for the Bay Area"
        />

        <div id="main">
          <section id="one" className="tiles">
            <article
              style={{
                backgroundImage: `url(${
                  this.props.data.pic02.childImageSharp.fluid.src
                })`,
              }}
            >
              <header className="major">
                <h3>Our Services</h3>
                <p>Bringing Hovercraft to the Bay Area</p>
              </header>
              <Link
                to="/consulting"
                className="link primary"
                aria-label="consulting"
              />
            </article>

            <article
              style={{
                backgroundImage: `url(${
                  this.props.data.pic01.childImageSharp.fluid.src
                })`,
              }}
            >
              <header className="major">
                <h2>What are hovercraft?</h2>
                <p>A new way to navigate the bay</p>
              </header>
              <Link
                to="/technology"
                className="link primary"
                aria-label="technology"
              />
            </article>
            {/* <article
              style={{
                backgroundImage: `url(${
                  this.props.data.pic01.childImageSharp.fluid.src
                })`,
              }}
            >
              <header className="major">
                <h3>Environmental Conservation</h3>
                <p>A commitment to protect and enhance San Francisco Bay.</p>
              </header>
              <Link
                to="/environment"
                className="link primary"
                aria-label="environment"
              />
            </article> */}

            {/* <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Accessibility</h3>
                <p>Travel to areas inaccessible to ferries.</p>
              </header>
              <Link
                to="/accessability"
                className="link primary"
                aria-label="accessability"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Equity</h3>
                <p>New commute, new livelihood</p>
              </header>
              <Link to="/equity" className="link primary" aria-label="equity" />
            </article> 
             <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>Government Relations</h3>
                <p>Bring HOVR to your bayside community</p>
              </header>
              <Link
                to="/government"
                className="link primary"
                aria-label="government"
              />
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>Employers</h3>
                <p>Get your team to work, not stuck in traffic</p>
              </header>
              <Link
                to="/employers"
                className="link primary"
                aria-label="employers"
              />
            </article> */}
          </section>
          {/* <section id="two">
            <div className="inner">
              <header className="major">
                <h2>What are hovercraft?</h2>
              </header>
              <p>
                Hovercraft are low-flying aircraft held aloft on a cushion of
                air, like a puck on an air-hockey table. This makes it fully
                amphibious, capable of operating not only over water, but also
                over mudflats, beaches, and inland (if it’s relatively flat).
                They come in a variety of shapes and sizes, from small ATV-sized
                recreational craft up to massive craft capable of carrying
                hundreds of passengers and dozens of cars.
              </p>
              <ul className="actions">
                <li>
                  <Link
                    to="/technology"
                    aria-label="technology"
                    className="button next"
                  >
                    Find out more
                  </Link>
                </li>
              </ul>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { eq: "landry-gapangwa-737556-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pic01: file(relativePath: { eq: "landry-gapangwa-737553-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic02: file(relativePath: { eq: "owen-cl-148926-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
